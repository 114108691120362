import "./style.scss";
import React from "react";

const HeadingWithCopy = (props) => {
  const sectionTitle = props && props.title ? props.title : ''
  const copy = props && props.contentCopy ? props.contentCopy : ''

  return (
    <div className="heading__wrap hwc__wrap flex">
      {copy !== '' ? (
          <div className="section__copy hwc__copy text--copy text--white">
            {copy}
          </div>
        ) : null
      }
      {sectionTitle !== '' ? (
          <h2 className="section__title hwc__title">{sectionTitle}</h2>
        ) : null
      }
     
    </div>
  );
};

export default HeadingWithCopy;
